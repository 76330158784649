import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { mainStore, syngooConfiguracaoStore } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import PlugnotasBoasVindasComponent from '@/components/PlugnotasBoasVindasComponent.vue';
let VisaoGeral = class VisaoGeral extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.configuracao = null;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async mounted() {
        this.loading = true;
        this.configuracao = await syngooConfiguracaoStore.getSyngooConfiguracao();
        this.loading = false;
    }
};
VisaoGeral = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            PlugnotasBoasVindasComponent,
        },
    })
], VisaoGeral);
export default VisaoGeral;
